//
// globe.js
// Theme module
//

import Globe from 'globe.gl'

// Gen random data
const N = 5;
const arcsData = [...Array(N).keys()].map(() => ({
    startLat: (Math.random() - 0.5) * 180,
    startLng: (Math.random() - 0.5) * 360,
    endLat: (Math.random() - 0.5) * 180,
    endLng: (Math.random() - 0.5) * 360,
    color: 'rgba(136, 84, 208, 0.5)',
}));


fetch(getBaseUrl() + 'assets/json/ne_110m_admin.json').then(res => res.json()).then(countries => {
    if (document.getElementById('globeViz') == null) {
        return
    }
    const world = Globe({ animateIn: false })
        (document.getElementById('globeViz'))
        .backgroundColor('rgba(0,0,0,0)')
        .showGlobe(false)
        .showAtmosphere(false)
        .enablePointerInteraction(false) // performance improvement
        .hexPolygonsData(countries.features)
        .hexPolygonResolution(3)
        .hexPolygonMargin(0.3)
        .enablePointerInteraction(() => false)
        .width(500)
        .height(600)
        .hexPolygonColor(() => `rgba(52, 94, 234, 0.5)`)
        .arcsData(arcsData)
        .arcColor('color')
        .arcDashLength(() => Math.random())
        .arcDashGap(() => Math.random())
        .arcDashAnimateTime(() => Math.random() * 4000 + 1000)

    // Add auto-rotation
    world.controls().enableZoom = false;
    world.controls().autoRotate = true;
    world.controls().autoRotateSpeed = 0.4;
    world.controls().enabled = true;

});

function getBaseUrl() {
    return window.location.href.match(/^.*\//);
}